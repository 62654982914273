import React, { useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";

import ApproveButton from "./components/ApproveButton";
import ClaimButton from "./components/ClaimButton";
import ParticipateButton from "./components/ParticipateButton";
import AddSusd from "./components/AddSusd";

import "./App.css";
import logo from "./logo.png"; // Adjust this according to your logo file path

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: {},
  disableInjectedProvider: false,
});

function App() {
  const [account, setAccount] = useState("");
  const [web3, setWeb3] = useState(null);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      web3Modal.connect().then(handleConnect);
    }
  }, []);

  const handleConnect = async (provider) => {
    const web3 = new Web3(provider);
    setWeb3(web3);

    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);

    // Listen for account changes
    provider.on("accountsChanged", async (accounts) => {
      // Either reconnect or set the new account
      const userResponse = window.confirm(
        "Account changed. Would you like to connect with the new account?"
      );

      if (userResponse) {
        setAccount(accounts[0]);
      } else {
        await web3Modal.connect();
      }
    });

    // Listen for disconnect event
    provider.on("disconnect", (error) => {
      console.log("Provider disconnected");
      // clear cache and refresh page to allow user to reconnect
      web3Modal.clearCachedProvider();
      window.location.reload();
    });
  };

  return (
    <div className="App">
      <div className="container">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="title">Soverun USD Faucet</h1>
        {web3 ? (
          <>
            <h2 className="wallet-address">
              Connected Wallet: <span>{account}</span>
            </h2>
            <div className="buttons">
              <AddSusd web3={web3} />
              <ClaimButton web3={web3} account={account} />
              <ApproveButton web3={web3} account={account} />
              <ParticipateButton web3={web3} account={account} />
              <button
                onClick={() => {
                  web3Modal.clearCachedProvider();
                  window.location.reload();
                }}
              >
                Disconnect
              </button>
            </div>
          </>
        ) : (
          <div className="buttons">
            <button onClick={() => web3Modal.connect().then(handleConnect)}>
              Connect Wallet
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
