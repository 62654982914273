import React from "react";

const FAUCET_CONTRACT_ADDRESS = "0x4238D2C3A77177059779736dF21Ce25168E942e4";
const FAUCET_CONTRACT_ABI = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [
      { type: "address", name: "token", internalType: "contract IERC20" },
      { type: "uint256", name: "_claimAmount", internalType: "uint256" },
    ],
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        type: "address",
        name: "previousOwner",
        internalType: "address",
        indexed: true,
      },
      {
        type: "address",
        name: "newOwner",
        internalType: "address",
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claim",
    inputs: [{ type: "address", name: "recipient", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "claimAmount",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "refill",
    inputs: [{ type: "uint256", name: "amount", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "renounceOwnership",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "transferOwnership",
    inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
  },
];

function ClaimButton({ web3, account }) {
  const handleClick = async () => {
    const contract = new web3.eth.Contract(
      FAUCET_CONTRACT_ABI,
      FAUCET_CONTRACT_ADDRESS
    );
    try {
      await contract.methods.claim(account).send({ from: account });
      alert("Claimed Successfully!");
    } catch (error) {
      console.error("An error occurred", error);
      alert("An error occurred");
    }
  };

  return <button onClick={handleClick}>1. Claim SUSD</button>;
}

export default ClaimButton;
