import React from "react";

const AddTokenButton = ({ web3 }) => {
  const tokenAddress = "0x4fecD680f41b73c7A2abf2713Ee8F7547C50f98f";
  const tokenSymbol = "SUSD";
  const tokenDecimals = 18;
  const tokenImage =
    "https://img.simplerousercontent.net/scaled_image/8007164/fa30d3decf9dfe7880716af94ae117c6ce846ac0/XUSD-150w-150h.png";

  const addToken = async () => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await web3.currentProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20 tokens, but eventually more!
          options: {
            address: tokenAddress, // The address of the token.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 characters.
            decimals: tokenDecimals, // The number of decimals in the token.
            image: tokenImage, // A string URL of the token logo.
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return <button onClick={addToken}>Add SUSD Token</button>;
};

export default AddTokenButton;
